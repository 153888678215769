
    import { defineComponent, ref, onMounted, onUnmounted, reactive, toRefs } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { getWording, getBudget } from "@/API/approve";
    import blobDownload from "@/utils/blobDownload";
    import { useStore } from 'vuex'
    export default defineComponent({
        name: "wordingBudget",
        setup() {
            const store = useStore()
            const route = useRoute();
            const router = useRouter();
            const wordingSrc = ref('');
            const wordingSrc1 = ref('');
            const init = () => {
                const params = {
                    programId: '',
                    programPeriod: ''
                }
                if (sessionStorage.getItem('paramsWording') && sessionStorage.getItem('paramsWording') !== null) {
                    Object.assign(params, JSON.parse(sessionStorage.getItem('paramsWording') as string))
                }
                // wording图片
                // getWording(params.programId, params.programPeriod).then(data => {
                //     const img = new Image();
                //     const url = window.URL.createObjectURL(data);
                //     img.onload = () => {
                //         window.URL.revokeObjectURL(img.src);
                //     }
                //     img.src = url;
                //     wordingSrc.value = url;
                // })
                // // budget图片
                // getBudget(params.programId, params.programPeriod).then(data => {
                //     const img = new Image();
                //     const url = window.URL.createObjectURL(data);
                //     img.onload = () => {
                //         window.URL.revokeObjectURL(img.src);
                //     }
                //     img.src = url;
                //     wordingSrc1.value = url;
                // })
            }
            const handleGo = () => {
                const params = {
                    programId: '',
                    programPeriod: ''
                }
                if (sessionStorage.getItem('paramsWording') && sessionStorage.getItem('paramsWording') !== null) {
                    Object.assign(params, JSON.parse(sessionStorage.getItem('paramsWording') as string))
                }
                router.push({
                    path: '/budgutinputcontrolling',
                    query: {
                        pgmId: params.programId
                    }
                })
            }
            const handleWordingDownload = () => {
                const params = {
                    programId: store.state.pgmData.programId,
                    type: 'Internal',
                    lang: 'en',
                    image: false
                }
                blobDownload({url: '/pgapi/program/wording/preview_pdf', params: params})
            }
            onMounted(() => {
                init()
                // 需要在浏览器的标签上显示program Name 和code
                if (sessionStorage.getItem('paramsWording') && sessionStorage.getItem('paramsWording') !== null) {
                    document.title = JSON.parse(sessionStorage.getItem('paramsWording') as string).programName as string
                }
            })
            onUnmounted(() => {
                document.title = ''
            })
            
            return {
                wordingSrc,
                wordingSrc1,
                handleGo,
                handleWordingDownload
            }
        }
    })
